@import './../../../../styles/color';



.radio-box{
    width: 28px;
    height: 20px;
    border-radius: 32px;
    background-color: $input-border-color;
    display: flex;
    align-items: center;
    transition: 0.4s;
    cursor: pointer;
    .radio-cicle{
        width: 14px;
        height: 14px;
        background-color: $box-background;
        border-radius: 8px;
        margin-left: 3px;
        transition: 0.4s;
    }
 
}
.select-radio{
    background-color: $primary-color;
        .radio-cicle{
        margin-left: 12px;

            }
}