@import './../../../../styles/color';

.noticie-card{
    width: calc(33% - 8px);
      .noticie-card-img{
        width: 100%;
        height: 200px;
        background-size: cover;
        background-position: center;
    }
    .notice-card-content{
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .notice-card-title{
            padding: 0px;
            margin: 0;
        }
        .notice-card-category{
            font-weight: 600;
            text-transform: capitalize;
            color: $primary-color;
            
        }
    } 
    
}

    @media only screen and (max-width: 975px) {
        .noticie-card {
             width: calc(50% - 8px);
        }
}