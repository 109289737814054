@import "./../../../../../../styles/color";

.trip-box {
    width: 156px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        box-shadow: 0px 0px 16px $box-shadow-hover;
    }
    .porcent {
        z-index: 1;
        position: absolute;
        margin-top: 6px;
        width: 90px;
        text-align: center;
    }
    .trip-content {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 44px;
    }
}

@media (max-width: 531px) {
    .trip-box {
        width: calc(50vw - 24px);
    }
}
