.create-user-details{
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    gap: 32px;
    footer{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
    }
    h3{
        margin-bottom: 16px;
    }
}
.create-user-details-main{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100vw;
    gap: 32px;
    flex-wrap: wrap;
    margin-left: -128px;
    .create-user-details-content{
        padding: 16px;
        width: 460px!important;
        max-width: calc(85vw );
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p{
            text-align: center;
            margin: 0;
            margin-bottom: 32px;
        }
        form{
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            button{
                margin-top: 32px;
            }
        }
    }
}

@media only screen and (max-width: 830px) {
    .create-user-details-main{
        margin-left: 0;
        .person{
            display: none;
        }
    }
}