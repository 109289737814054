@import "./../../../styles/color";

.simulation {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 16px;
    .simulation-input {
        width: 100%;
        padding: 16px;
        height: max-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .simulation-type {
            h4 {
                font-weight: 600;
                font-size: 11pt;
                margin-bottom: 8px;
            }
            margin-bottom: 32px;
            div {
                display: flex;
                gap: 16px;
                .selected {
                    background-color: $primary-color;
                    border-color: $primary-color;
                    color: $title-color;
                    &:hover {
                        background-color: $primary-color;
                        border-color: $primary-color;
                        color: $title-color;
                    }
                }
                button {
                    img {
                        width: 28px;
                    }
                    width: 50%;
                    background: none;
                    border: 1px solid $input-border-color;
                    border-radius: 4px;
                    padding: 8px 32px;
                    color: $paragraph-dark-color;
                    transition: 0.2s;
                    &:hover {
                        background-color: $input-border-color;
                        color: $title-color;
                        border-color: $input-border-color;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 990px) {
    .simulation {
        grid-template-columns: 1fr;
    }
}
