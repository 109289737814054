@import "./color";

.clean-input {
    border: 2px solid $input-border-color;
    border-radius: 8px;
    padding: 12px;

    input,
    textarea {
        background-color: $box-background !important;
        z-index: 4;
        border: none !important;
        padding: 0;
        background: none;
        margin-top: -28px;
        transition: 0.1s;
        &:not([value=""]) {
            margin-top: 0px;
        }
        &:focus {
            margin-top: 0px;
        }
    }
}

label {
    margin: 0 auto;
    width: 100%;
    h4 {
        margin: 0;
        margin-bottom: 8px;
        font-size: 14px;
    }
    display: flex;
    width: 100%;
    flex-direction: column;
}
input,
select,
textarea {
    border-radius: 8px;
    border: 1px solid $input-border-color;
    padding: 12px;
    margin: 0;
    width: -webkit-fill-available;
    color: $paragraph-dark-color;
    background-color: rgba(255, 0, 0, 0);
}
input::placeholder,
textarea::placeholder {
    opacity: 0.6;
}
input:focus {
    outline: none !important;
}
