@import "./../../../../styles/color";

.button-add-page {
    position: fixed;
    bottom: 12px;
    display: flex;
    justify-content: end !important;
    width: 100%;
    left: -18px;
    height: max-content;
    z-index: 3;

    .button-add {
        position: fixed;
        bottom: 68px;
        background-color: $primary-color;
        width: 48px;
        height: 48px;
        border-radius: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 3px 3px 20px $box-shadow-green;
        opacity: 1;
        transition: 0.4s;
        padding: 0;
        cursor: pointer;
        float: right;
        &:hover {
            opacity: 1;
        }
        img {
            width: 18px;
        }
    }

    .page-backgroud {
        animation: backgroundAnimation 0.4s linear forwards;
        width: 100vw;
        height: 100vh;
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        z-index: 2;
    }

    .button-add-content {
        width: max-content;
        height: 100vh;
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        overflow: scroll;
        z-index: 3;
    }
}
