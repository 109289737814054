@import "./../../styles/color";

.message-box {
    min-width: 300px;
    max-width: 460px;
    display: flex;
    position: fixed;
    top: 32px;
    z-index: 1000;
    animation-name: moviment;
    animation-duration: 10s;
    height: max-content;
    justify-content: center;
    align-items: center;
    right: -500px;
    border-radius: 8px;
    .text {
        height: 100%;
        max-width: 50vw;
        p {
            font-size: 14px;
            margin: 8px 0;
        }
    }
    .close {
        height: 100%;
        img {
            width: 20px;
        }
    }
}
@keyframes moviment {
    0% {
        right: -500px;
    }
    5% {
        right: 0;
    }
    95% {
        right: 0;
    }
    100% {
        right: -500px;
    }
}
.message-box-white {
    background-color: rgb(255, 255, 255);
    display: flex;
    height: 100%;
    min-height: 80px;
    padding: 16px;
    min-width: 170px;
    button {
        background: none;
        border: none;
    }
    h3 {
        padding: 0;
        margin: 0;
    }
    .text {
        min-width: 130px;
    }
    .close {
        img {
            width: 16px;
        }
    }
    .message-time-box {
        width: 0%;
        height: 4px;
        margin-bottom: -16px;
        border-radius: 8px;
        transition: 10s linear;
    }
    .message-time-box-animation {
        width: 100%;
    }
}
.message-box-img {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
}

.color-atention {
    background-color: $quartenary-color;
}
.color-success {
    background-color: $primary-color;
}
.color-error {
    background-color: $terciary-color;
}
