@import './../../../styles/color';
.advice-opened {
    padding: 0!important;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .advice-opened-slide{

        display: flex;
        gap: 8px;
        flex-direction: column;
        .advice-opened-bigImg{
        display: flex;
        width: 100%;
        height: 300px;
        background-size: cover;
        background-position: center;
        justify-content: space-between;
        border-radius: 4px;
        overflow: hidden;
        div{
            display: flex;
            padding: 16px;
            align-items: center;
            height: 100%;
            width:50%;
            background: rgb(0,0,0);
            opacity: 0.4;
            transition: 0.6s;
            svg{
                opacity: 0.8;
                width: 24px;
            }
            &:hover{
                opacity: 1;
            }
        }
        .advice-opened-back{
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.405) 0%, rgba(1,1,1,0) 58%);
        }
        .advice-opened-next{
            justify-content: flex-end;
            background: linear-gradient(270deg, rgba(0,0,0,0.405) 0%, rgba(1,1,1,0) 58%);
        }
    }
    .advice-opened-imgs{
        display: flex;
        padding: 4px;
        gap: 8px;
        width: 100%;
        .advice-opened-img{
            cursor: pointer;
            width: calc(20% - 8px);
            height: 32px;
            background-size: cover;
            border-radius: 4px;
        }
        
    }
}
    .advice-opened-text{
        padding: 0 16px;
        display: flex;
        gap: 32px;
        flex-direction: column;

        h4{
            padding: 0;
            margin: 0;
        }
        .advice-opened-title{
            font-weight: 900;
            font-size: 18pt;
            color: $title-color;
            display: flex;
            flex-direction: column;
            gap: 4px;
            .advice-opened-categorie{
                display: flex;
                align-items: center;
                display: flex;
                font-weight: 300!important;
                gap: 4px;
                .advice-opened-categorie-icon{
                    background-color: $primary-color;
                    border-radius: 2px;
                    overflow: hidden;
                    padding: 1px;
                    div{
                        width: 12px;
                        background-size: cover;
                        height: 12px;
                        filter: contrast(-0);

                    }

                }
            }
        }
        .advice-opened-address{
            display: flex;
            flex-direction: column;
            gap: 4px;
        }

}
}