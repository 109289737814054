.login-form-main{
    padding: 32px 48px;
    width: calc(100vw - 16px);
    max-width: 460px!important;
    margin-top: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    .login-form-content{
        display: flex;
        gap: 32px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 360px!important;
    }
}