@import "./../../../styles/color";

.principal-article {
    display: grid;
    width: 100%;
    grid-template-columns: 4fr 5fr;
    .principal-article-img {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
    }
    .principal-article-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 32px;
        gap: 16px;
        .principal-article-content-title {
            padding: 0;
            margin: 0;
            font-size: 26pt;
        }
        .princpal-article-content-category {
            span {
                font-size: 11pt;
                font-weight: 600;
                color: $primary-color;
                text-transform: capitalize;
            }
        }

        .principal-article-content-creator {
            span {
                font-size: 11pt;
                font-style: italic;
            }
        }
    }
}

@media only screen and (max-width: 720px) {
    .principal-article {
        display: contents;
    }
    .principal-article-img {
        height: 300px !important;
    }
}
