.goals-box-others {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    gap: 8px;
    .goals-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        .goal-porcent {
            font-size: 11px;
        }
    }
}
