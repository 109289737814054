

.mobile{
    display: none;
}
@media only screen and (max-width: 990px) {
  .web {
    display: none;
  }
  .mobile{
    display: block;
  }
}