@import "./../../../styles/color";

.principal-advices-background-zoom {
    background-size: 120% !important;
}
.principal-advices-background {
    height: 600px;
    background-size: 110%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    transition: 0.3s;
    transition: background-size 8s;
    cursor: pointer;

    .principal-advices-bottons {
        background: rgb(0, 0, 0);
        padding: 32px;
        width: 100%;
        height: 100%;
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
        align-items: end;
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(1, 1, 1, 0) 58%);
        .principal-advices-others-photos {
            display: flex;
            gap: 8px;
            margin-bottom: 16px;
            .principal-advices-others-photo {
                width: 36px;
                height: 36px;
                background-size: cover;
                border-radius: 8px;
                opacity: 0.7;
                transition: 0.3s;
                &:hover {
                    opacity: 1;
                    transform: scale(1.06);
                    cursor: pointer;
                }
            }
        }
        .principal-advices-text {
            display: flex;
            flex-direction: column;
            align-items: end;
            gap: 8px;
            text-align: end;
            cursor: pointer;
            .principal-advices-categorie {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: -8px;
                display: flex;
                .principal-advices-categorie-icon {
                    width: 18px;
                    background-size: cover;
                    height: 18px;
                }
            }
            .principal-advices-title {
                font-size: 32pt;
                font-weight: 900;
                color: $background;
            }
        }
    }
}
