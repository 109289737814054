@import "./../../../../../styles/color";

.goals-itens {
    h4 {
        font-size: 12pt;
        font-weight: 600;
        margin-bottom: 8px;
    }
    table {
        width: 100%;
        th,
        td {
            flex: 1;
            text-align: center;
        }
        .goals-table-title-title,
        .goals-table-value-title {
            text-align: left;
        }
        .goals-table-title-value,
        .goals-table-value-value {
            text-align: right;
        }
        .goals-table-value {
            padding: 4px 0px;
            border-bottom: 1px solid $input-border-color;
            border-radius: 4px;
            font-weight: 400;
            transition: 0.3s;
            cursor: pointer;
            &:hover {
                background-color: $input-border-color;
                padding: 4px 2px;
            }
        }
        gap: 16px;
        tr {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $input-border-color;
            padding-bottom: 8px;
            gap: 8px;
        }
        th {
            color: $title-color;
        }
    }
}
