@import "./../../../styles/color";

.box-fullpage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    min-width: 100vw;
    min-height: 100vh;
    top: 0;
    left: 0;
    z-index: 4;

    @keyframes fadeBackground {
        0% {
            background: rgba(0, 0, 0, 0);
            backdrop-filter: blur(0px);
        }
        100% {
            backdrop-filter: blur(1px);
            background: $box-fullpage-background;
        }
    }
    .box-fullpage-background {
        backdrop-filter: blur(0px);
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        min-width: 100vw;
        min-height: 100vh;
        top: 0;
        left: 0;
        animation: fadeBackground 0.6s ease forwards;
    }

    @keyframes boxAnimation {
        0% {
            margin-bottom: -600px;
        }
        100% {
            margin-bottom: 0px;
            opacity: 1;
        }
    }

    .padding-content {
        padding: 64px 16px 16px 16px;
    }

    .box-fullpage-box {
        z-index: 4;
        border: none;
        width: 480px;
        max-width: 95vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: 0.3s;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        animation: boxAnimation 0.4s ease forwards;
        background-color: $box-background;

        &::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }

        .box-fullpage-head {
            background-color: $box-background;
            border-radius: 4px;
            padding: 8px 16px;
            position: fixed;
            width: 480px;
            max-width: 95vw;
            margin-top: 0px;
            overflow: scroll;

            .title-box {
                margin: 0 auto;
                margin-top: 8px;

                width: max-content;
            }
            .btn-close {
                margin-top: -16px;
                background: none;
                border: none;
                padding: 0;
                display: flex;
                justify-content: end;
                width: 100%;
            }
        }
        .box-fullpage-content {
            width: 100%;

            .content {
                max-height: 70vh;
                overflow: scroll;
                display: flex;
                flex-direction: column;
                gap: 32px;
                padding-bottom: 34px;
            }
            .button {
                display: flex;
                gap: 16px;
            }
            .modal-box-opened {
                h3 {
                    text-align: center;
                }
                display: flex;
                flex-direction: column;
                gap: 16px;
            }
        }
    }
}

@media only screen and (max-width: 720px) {
    .box-fullpage-box {
        max-width: 100vw !important;
        bottom: 0 !important;
        position: absolute;
    }
}
