@import "./../../../../styles/color";

.finance-simple-result {
    margin-bottom: 16px;
    width: max-content;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .finance-simple-result-value {
        padding: 0;
        padding-top: 4px;
        margin: 0;
    }
}
