@import "./../../styles/color";

.confirm-email-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    .confirm-email-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: -128px;
        padding: 8px;
        img {
            max-height: 30vh;
        }
        .confirm-email-text {
            max-width: 468px;
            padding: 32px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }
    footer {
        bottom: 16px;
        position: absolute;
    }
}
