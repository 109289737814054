@import './../../../../styles/color';

.input-money{
    align-items: center;
    gap: 8px;
    width: max-content;
    width: auto;
    font-weight: 400;
    color: $paragraph-dark-color;
    descent-override: {

    };
    
    div{
        padding: 16px;
        border: 1px solid $input-border-color;
        border-radius: 4px;
        input{
            border: none;
            font-size: 48px;
            text-align-last: left;
            width: 360px;
            overflow: scroll;
            padding: 0;
            max-width: 70vw;
        }
        display: flex;
        align-items: end;
        span{
            margin-bottom: 8px;
            margin-right: 8px;
        }

    }

}