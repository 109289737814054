$primary-color: #6ad9a8;
$primary-color-1: #5ec398;
$secondary-color: #6958a3;
$terciary-color: #fa385f;
$quartenary-color: #f1f180;
$blue-color: #68d5d9;
$quintenary-color: #fafafa;
$title-color: #202525;
$paragraph-color: #c1c1c1;
$paragraph-light-color: #dfdfdf;
$input-border-color: #edf0eeed;
$paragraph-dark-color: #627169e7;
$box-background: #fdfdfd;
$box-fullpage-background: #1010106d;
$todolist-background: #f8f8f8;
$background: #f7f7f7;
$box-shadow: #73737307;
$box-shadow-hover: #2c443913;
$box-shadow-green: #6cd9aa6e;
