@import  './../../../../styles/color';

.remember{
    display: flex;
    align-items: center;
    gap: 8px;
}

.forget-password-btn{
    background:none;
    border: 1px solid $secondary-color;
    padding: 8px 16px;
    border-radius: 16px;
    color: $secondary-color;
    font-weight: 400;
    font-size: 10pt;
    opacity: 0.7;
    transition: 0.4s;
    &:hover{
        opacity: 1;
    }
}
.login-social{
    text-align: center;
}