.modal-forget-password{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    p{
        text-align: center;
        padding: 0;
        margin: 0;
    }
}