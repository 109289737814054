@import './../../../../styles/color';

.city-result{
    background-color: $background;
    padding:  16px ;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-top: none;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 90%;
    margin: 0 auto;
    li{
        list-style: none;
        font-weight: 400;
        font-style: italic;
        cursor: pointer;
        opacity: 0.7;
        margin-bottom: 4px;
        transition: 0.4s;
        &:hover{
            opacity: 1
            ;
        }
    }
}