@import './../../../../styles/color';

.btn-action{

    div{
        background-color: $primary-color;
        align-items: center;
        justify-content: center;
        display: flex;
        height: 18px;
        width:  18px;
        border-radius: 4px;
        img{
            width: 12px;
            padding: 2px;
        }

    }
}