@import './../../../../styles/color';
.button-options{
    background-color: $primary-color;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 2px;
    z-index: 2;
}