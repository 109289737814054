
.login-main-component{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100vw;
    min-height: 100vh;
    gap: 68px;
    flex-wrap: wrap-reverse;
}

@media only screen and (max-width: 990px) {
    .login-main-page{
            flex-direction: column;
    }
}
