
@import './../../../../styles/color';
.input-color{
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(2, 1fr); 
    justify-content: center;
    align-items: center;
    gap:8px;
    width: 100%;
    .color-selected{
        border-color: $title-color!important;
        opacity: 1!important;
    }
    div{
        border: 3px solid;
  flex-basis: calc(10% - 16px);
        height: 18px;
        border-radius: 4px;
        opacity: 0.5;
        cursor: pointer;
        transition: 0.4s;
        &:hover{
            opacity: 1;
        }
    }
}