.radio {
    display: flex;
    padding: 4px;
    align-items: center;
    height: 90px;
    .radio-img {
        max-width: 64px;
        max-height: 64px;
    }
}

@import "./../../styles/color";

.radio-controls {
    display: flex;
    padding: 8px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .radio-title {
        padding: 8px;
        span {
            font-size: 8pt;
        }
        h5 {
            padding: 0;
            margin: 0;
            margin-top: 4px;
        }
    }
}

.radio-img {
    height: 120px;
    width: 120px;
    border-radius: 4px;
    margin-left: 5px;
    background-size: contain;
}
.radios-btns-controler {
    height: max-content;
}
.radios-bts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    margin: 0 auto;
}

.radio-volume input[type="range"] {
    outline: 0;
    border: none;
    opacity: 0;
    width: 120px;
    padding: 0;
    margin: 0;
    z-index: 2;
    margin-top: -13px;
    position: relative;
}

.radio-volume-bg {
    background-color: #f7f7f7;
    width: 96px;
    height: 6px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.radio-volume-bar {
    height: 6px;
    background-color: $primary-color;
    border-radius: 10px;
}
