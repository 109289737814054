@import "./../../../styles/color";
.avatar-box {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;
    .avatar-text {
        text-align: right;
        display: flex;
        flex-direction: column;
        .avatar-name {
            font-size: 15px;
            font-weight: 700;
            text-align: right;
            color: $title-color;
        }
        .avatar-country {
            font-size: 12px;
        }

        img {
            width: 16px;
        }
    }
    .avatar-photo-box {
        cursor: pointer;

        display: flex;
        flex-direction: row;
        .avatar-photo {
            box-shadow: 1px 1px 3px #00000026;
            width: 48px;
            height: 48px;
            border-radius: 72px;
            background-size: cover;
            background-position: center;
        }
        .button-options {
            margin-left: -16px;
        }
    }
}
