

@import './../../../../../../styles/color';

.trip-domestic-grafic-box{
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;

        .trip-domestic-icon{
            background-color: $box-background;
            position: absolute;
            width: 82px;
            height: 82px;
            border-radius: 72px;
            display: flex;
            align-items: center;
            justify-content: center;
                margin-top: 44px;
                img{
                    margin-bottom: 8px;
                }
    
        }
    
    

}