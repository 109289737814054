@import "./../../../../../../styles/color";

.grafic-domestic-grafic-box {
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;

    .grafic-domestic-icon {
        background-color: $box-background;
        position: absolute;
        width: 42px;
        height: 42px;
        border-radius: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
