@import './../../../styles/color';

.title-of-session-dashboard{
    display: flex;
    width: 100%;
    justify-content: space-between;
    div{
        cursor: pointer;
    }
    h4{
        border-bottom: 1px solid transparent; 
        padding-bottom: 4px;
        width: max-content;
        margin-bottom: 16px!important;
        border-bottom: 2px solid transparent; 
        border-image: linear-gradient(to left, rgba(0, 255, 0, 0) ,rgba(0, 255, 0, 0)30%,$primary-color 0%, $primary-color) 10%;

    }
}