@import "./../../../styles/color";

.button-add-finance {
    position: fixed;
    display: flex;
    align-items: end;
    z-index: 0;
    bottom: 112px;
    right: 16px;
    z-index: 2;

    .page-container {
        max-width: 100px;
        flex-direction: row;
        display: grid;
        justify-content: end;
        align-items: end;
        grid-template-columns: auto;
        gap: 8px;
        padding-bottom: 0 !important;
        .option-animation {
            opacity: 1 !important;
        }
        .background-opened {
            opacity: 1;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            position: fixed;
            background-color: rgba(0, 0, 0, 0);
            animation: backgroundAnimation 0.4s linear forwards;
        }
        @keyframes backgroundAnimation {
            0% {
                background-color: rgba(0, 0, 0, 0);
            }
            50% {
                background-color: #00000013;
            }
            100% {
                background-color: #00000013;
            }
        }
        .button-add-finance-options {
            grid-column-end: 1;
            width: max-content;
            height: max-content;
            z-index: 2;
            flex-direction: column;
            display: grid;
            gap: 8px;
            padding: 0;
            li {
                border-radius: 8px;
                background-color: $box-background;
                &:hover {
                    background-color: $primary-color;
                    color: $title-color;
                }
                display: flex;
                align-items: center;
                gap: 8px;
                font-size: 14px;
                img {
                    width: 12px;
                    height: 12px;
                    opacity: 0.3;
                }
                list-style: none;
                padding: 12px 16px;
                opacity: 0;
                transition: 0.2s;
                cursor: pointer;
            }
        }
    }

    .button-add {
        background-color: $primary-color;
        width: 48px;
        height: 48px;
        border-radius: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 3px 3px 20px $box-shadow-green;
        opacity: 0.8;
        transition: 0.4s;
        padding: 0;
        cursor: pointer;
        .button-add-close {
            height: 3px;
            width: 16px;
            background-color: $title-color;
            border-radius: 8px;
        }
        &:hover {
            opacity: 1;
        }
        img {
            width: 18px;
        }
    }
}
