@import "./../../../../styles/color";

.principal-dashboard-article {
    display: grid;
    min-height: 410px;
    .principal-dashboard-article-img {
        height: 242px;
        width: 100%;
        background-size: cover;
        background-position: center;
    }
    .principal-dashboard-article-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px;
        gap: 8px;

        .princpal-article-dashboard-content-category {
            span {
                font-weight: 600;
                color: $primary-color;
                text-transform: capitalize;
            }
        }

        .principal-dashboard-article-content-creator {
            span {
                font-style: italic;
            }
        }
    }
}

@media only screen and (max-width: 720px) {
    .principal-dashboard-article {
        display: contents;
    }
    .principal-dashboard-article-img {
        height: 300px !important;
    }
}
