@import "./../../../../styles/color";

button {
    font-size: 11pt;
    font-weight: 600;
}
.button-simple {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 8px;
    transition: 0.4s;
    img {
        width: 24px;
    }
}
.button-simple:hover {
    background-color: $primary-color-1;
    transition: 0.4s;
}

.button-inactive {
    opacity: 0.7;
}
.button-success {
    background-color: $primary-color;
    box-shadow: 8px 8px 22px $box-shadow-green;
}

.button-delete {
    box-shadow: none;
    padding: 6px;
    font-weight: 400;
    border: 0.4px solid $terciary-color;
    color: $terciary-color;
    background-color: #ff00;
    opacity: 0.5;
    &:hover {
        opacity: 1;
        background-color: #ff00;
    }
}
.btn-loading {
    width: 32px;
}
