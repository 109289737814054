@import './../../../styles/color';

.toDoList-dashboard{
  .list-todolist{
    height: 700px;
    overflow: scroll;
    border: 1px solid $input-border-color;
    padding: 8px;
    border-radius: 8px;
    .todolist-card{
      margin-bottom: 8px;
    }
  }
}