@import "./../../styles/color";

.planner-ticket-top {
    display: flex;
    background-color: $primary-color;
    justify-content: center;
    gap: 16px;
    align-items: center;
    svg {
        width: 16px;
    }
    div {
        color: $title-color;
    }
}
.planner-ticket-bottom {
    align-items: center;
    display: flex;
    h5 {
        margin-bottom: 8px;
        margin-top: 4px;
        flex-direction: row;
        display: flex;
    }
    display: flex;
    padding: 8px;
    justify-content: space-between;
    div {
        width: 50%;
        display: flex;
        flex-direction: column;
    }
    .planner-ticket-bottom-right {
        h1 {
            margin: 0;
            margin-top: 4px;
        }
    }
    button {
        background: none;
        border: 1px solid $primary-color;
        color: $primary-color;
        width: 100%;
        border-radius: 32px;
        padding: 4px;
        transition: 0.3s;
        &:hover {
            background-color: $primary-color;
            color: $title-color;
        }
    }
}
