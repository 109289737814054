@import "./../../../../styles/color";

.trip-finances-resume {
    .trip-finances-resume-texts {
        display: flex;
        justify-content: space-between;
        gap: 16px;
    }
    .trip-bar {
        width: 100%;
        height: 32px;
        background-color: $input-border-color;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        div {
            height: 100%;
            max-width: 100%;
            border-radius: 2px;
        }
        .trip-bar-results {
            height: 100%;
            display: flex;
            .trip-bar-spent {
                background-color: $primary-color;
            }
            .trip-bar-cashInHand {
                background-color: $quartenary-color;
            }
        }
        .trip-bar-rest {
            background-color: $input-border-color;
        }
        div {
            height: 100%;
            max-width: 100%;
            border-radius: 2px;
        }
    }
}
