@import './../../../../styles/color';

.input-upload{
    cursor: pointer;
    width: 100%;
    height: 100px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: center;
    border: 1px dashed $input-border-color;
    span{
        font-weight: 300;
        padding-bottom: 16px;
    }
    div{
        width: 100%;
        position: absolute;
        
    }
    img{
        padding: 8px 0;
        width: 100px;
        height: 300px;
        opacity: 0.1;
    }
    input{
        width: 100%;
        height: 100px;
        opacity: 0;
    }
}