@import "./../../../../styles/color";
.doughnut-half {
    display: flex;
    align-items: center;
    justify-content: center;
    .porcents {
        position: absolute;
        margin-top: 52px;
        img {
            width: 24px;
        }
    }
}
