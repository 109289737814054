@import './../../../styles/color';

.title-of-component{
    border-bottom: 1px solid transparent; 
    padding-bottom: 4px;
    width: max-content;
    margin-bottom: 16px!important;
    font-size: 12pt;
    color: $secondary-color;

}