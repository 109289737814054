@import './color';
h1, h2,h3,h4,h5 {
    font-family: 'Lato', sans-serif;
    font-weight: 800;
    margin: 0;
    padding: 0;
}

 button, h1, h2,h3,h4,h5 ,input{
    color: $title-color;

}

a{
    color:$paragraph-color
}
span{
    color: $paragraph-color;
    font-size: 10pt;
    margin: 0;
    padding: 0;
}
.span-small{
    font-size: 8pt;

}

.span-big{
    
}


h1{
    font-size: 42pt;
    line-height: 38pt;
}
h2{
    font-size: 32pt;
}
h3{
    font-size: 24pt;
}
h4{
    font-size: 14pt;
}
h5{
    font-size: 10pt;
}

input{
    font-size: 16px;
}


 div,input{
    color: $paragraph-color;
    font-size: 10pt;
}

