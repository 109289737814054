@import "./../../../../styles/color";
.finances-actives {
    flex: 1;
    .finances-actives-table {
        min-height: 272px;
        overflow-y: auto;
        tbody {
            max-height: 200px;
            display: block;
        }
        table {
            width: 100%;
            text-align: left;
            font-size: 11pt;
            .bord {
                font-weight: 600;
                color: $title-color;
            }
            .options {
                width: 10px;
                display: flex;
            }
            tr {
                width: 100%;
                display: flex;
                justify-content: space-between;
                &:hover {
                    background-color: $input-border-color;
                }

                vertical-align: middle;
            }
        }
        th {
            color: $title-color;
            padding: 16px;
            flex: 1;
        }
        td {
            padding: 10px 16px;
            flex: 1;
            border-bottom: 1px solid $input-border-color;
        }
        .date {
            font-size: 9pt;
            text-align: center;
        }
        .value {
            font-size: 9pt;
            font-weight: 600;
            text-align: right;
        }
        .finances-actives-item {
            td {
                min-width: 100px;
                div {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }
            }
            .item-color {
                width: 6px;
                height: 12px;
                border-radius: 8px;
            }
        }
    }
}
.no-activities {
    padding: 32px;

    height: 100px;
    font-style: italic;
    font-size: 14px;
}
