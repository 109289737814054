@import './../../../../styles/color';

.delete-small{
    cursor: pointer;
    img{
        width: 12px;
    }
    svg{
        width: 12px;
    }
            svg, g ,path{
            color: $paragraph-color;
            stroke: $paragraph-light-color;
            fill: $box-background;
            transition: 0.2s;
            &:hover{
                stroke: $terciary-color;
            }
        }
}