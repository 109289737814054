.finances-resume {
    padding: 8px;
    width: 100%;
    .finance-resume-components {
        display: flex;
        min-height: 252px;
        align-items: center;
        justify-content: space-around;
    }
}
