@import "./color";

.box {
    background-color: $box-background;
    border: none;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 0px 8px $box-shadow;
}

.page-container {
    width: 1450px;
    max-width: calc(100vw - 112px);
    margin: 0 auto;
    padding: 0 16px;
    padding-left: calc(16px + 32px);
    padding-bottom: 112px;
}

.display-none {
    display: none;
}
.display-flex {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
}

hr {
    border: none;
    height: 1px;
    background-color: $input-border-color;
    width: 100%;
    margin: 32px auto;
}

@media only screen and (max-width: 720px) {
    .page-container {
        padding: 0 16px;
        max-width: calc(100vw);

        margin: 0 auto;
    }
}
