
@import './../../../../styles/color';
.todolist-comments-card{
    display: flex;
    border-radius: 4px;
    border: 1px solid $input-border-color;
    align-items: center;
    padding: 16px;
    justify-content: space-between;
    margin-bottom: 4px;
    .todolist-comments-left{
        width: 100%;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .todolist-comments-title{
            color: $paragraph-dark-color;
            font-weight: 400;
        }
        .todolist-comments-description{
            font-weight: 400;
            font-size: 10pt;
            color: $paragraph-color;
        }
        .todolist-comments-date{
            border: 1px solid $input-border-color;
            border-radius: 16px;
            padding: 4px 8px;
            width: max-content;
            font-weight: 300;
            font-size: 8pt;
            color: $paragraph-color;
        }
   

    }
}

.todolist-comments-input{
    gap: 16px;
    display: flex;
    padding: 8px;
}